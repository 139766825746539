Ember.TEMPLATES["javascripts/discourse/connectors/user-card-additional-buttons/showaward"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1,[\"can_show_award_button\"]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"class\",\"action\",\"icon\",\"label\"],[\"btn-primary\",[28,[37,3],[[30,0],[33,1,[\"award_point\"]],[33,1,[\"username\"]]],null],\"book\",\"point.award_button_text\"]]]],[1,\"\\n  \"],[1,[28,[35,4],null,[[\"type\",\"value\",\"min\",\"max\",\"step\"],[\"number\",[33,1,[\"awardpoint\"]],-50000,50000,500]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"user\",\"d-button\",\"action\",\"input\"]]","moduleName":"javascripts/discourse/connectors/user-card-additional-buttons/showaward","isStrictMode":false});
Ember.TEMPLATES["javascripts/discourse/connectors/user-card-post-names/point"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,1],[14,1,\"userpoint\"],[12],[1,[30,0,[\"user\",\"point\"]]],[1,\" point\"],[13],[1,\"\\n\"]],[],false,[]]","moduleName":"javascripts/discourse/connectors/user-card-post-names/point","isStrictMode":false});
Ember.TEMPLATES["javascripts/discourse/connectors/user-summary-stat/point"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[28,[35,0],null,[[\"value\",\"label\"],[[33,1,[\"point\"]],\"point.number\"]]]],[1,\" \\n\"]],[],false,[\"user-stat\",\"model\"]]","moduleName":"javascripts/discourse/connectors/user-summary-stat/point","isStrictMode":false});
define("discourse/plugins/discourse-point/discourse/pre-initializers/extend-user-for-point", ["exports", "discourse/models/user", "discourse/lib/ajax"], function (_exports, _user, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "extent-user-for-point",
    before: "inject-discourse-objects",

    initialize() {
      _user.default.reopen({
        awardpoint: 500,
        point: Ember.computed("custom_fields.point", {
          get(fieldName) {
            return Ember.get(this.custom_fields, fieldName);
          }

        }),
        can_show_award_button: Ember.computed("can_show_award_button", {
          get() {
            return this.can_show_award_button;
          }

        }),

        award_point(user) {
          return (0, _ajax.ajax)("/user-award-point", {
            type: "POST",
            data: {
              username: user,
              point: this.user.awardpoint
            }
          }).then(json => {
            var cf = json.user.custom_fields;
            var newpoint = cf.point;
            var pointspan = $('#userpoint');
            pointspan.text(newpoint.toString());
          });
        }

      });
    }

  };
  _exports.default = _default;
});

